<template>
  <div id="footer">
    <div class="footer-container">
      <div class="links">
        <div class="column">
          <div class="header">خدمات ویستا</div>
          <img class="liner" src="../assets/images/Liner.svg" alt="" />
          <div class="body">
            <div @click="goDigitalCurrencyTransactions">ارز دیجیتال</div>
            <div @click="goWebMoneyService">خدمات وب مانی</div>
            <div @click="goPerfectMoneyService">خدمات پرفکت مانی</div>
            <div @click="goPayPalService">خدمات پی پل</div>
            <div @click="goSkrillService">خدمات اسکریل</div>
            <div>خدمات ووچر</div>
            <div @click="goPsVoucher">پی اس ووچر</div>
          </div>
        </div>
        <div class="column">
          <div class="header">دسترسی سریع</div>
          <img class="liner" src="../assets/images/Liner.svg" alt="" />
          <div class="body">
            <div @click="goHome">صفحه ی اصلی</div>
            <div @click="goAccount">حساب کاربری</div>
            <div @click="goFAQ">سوالات متداول</div>
            <div @click="goBlog">وبلاگ</div>
            <div @click="goTermsAndConditions">قوانین و مقررات</div>
            <div @click="goAboutUs">درباره ی ما</div>
            <div @click="goContactUs">تماس با ما</div>
          </div>
        </div>
      </div>
      <div class="contact-us">
        <div class="header">ارتباط باما</div>
        <img class="liner" src="../assets/images/Line4(1).svg" alt="" />
        <div class="body">
          <div class="address">
            <div>آدرس : خیابان شالی، نرسیده به امیرفخری، پلاک ۳۵۵ واحد۹</div>
            <div class="phone">
              <div>شماره تماس:</div>
              <div>۰۲۱-۴۴۷۶۸۷۶۳-۶</div>
              <div>۰۹۱۲-۵۶۴۵۶۴۹۰</div>
            </div>
            <div class="email">
              <div>آدرس ایمیل :</div>
              <div>momomom.momo@gmaile.com</div>
            </div>
            <div>ویستا در شبکه ها ی مجازی</div>
            <div class="social">
              <img
                src="../assets/images/ant-design_whats-app-outlined.svg"
                alt=""
              />
              <img src="../assets/images/uil_twitter-alt.svg" alt="" />
              <img src="../assets/images/simple-icons_instagram.svg" alt="" />
              <img src="../assets/images/uil_telegram-alt.svg" alt="" />
              <img
                src="../assets/images/grommet-icons_linkedin-option.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="news">
          <input type="email" placeholder="پست الکترونیکی" />
          <div class="btn">عضویت</div>
        </div>
      </div>
    </div>
    <img class="liner-bottom" src="../assets/images/bottom-liner.svg" alt="" />
    <div class="copyright">
      کليه حقوق محصولات و محتوای اين سایت متعلق به ویستا می باشد.
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    goAccount() {
      this.$router.push("/account-authentication");
    },
    goDigitalCurrencyTransactions() {
      this.$router.push("/digital-currency-equations");
    },
    goPerfectMoneyVoucher() {
      this.$router.push("/voucher-services");
    },
    goHotVoucher() {
      this.$router.push("/hot-voucher");
    },
    goPsVoucher() {
      this.$router.push("/ps-voucher");
    },
    goPerfectMoneyService() {
      this.$router.push("/perfect-money-service");
    },
    goWebMoneyService() {
      this.$router.push("/web-money-service");
    },
    goPayPalService() {
      this.$router.push("/paypal-service");
    },
    goSkrillService() {
      this.$router.push("/skrill-service");
    },
    goTermsAndConditions() {
      this.$router.push("/privacy-policy");
    },
    goBlog() {
      this.$router.push("/blog");
    },
    goFAQ() {
      this.$router.push("/faq");
    },
    goAboutUs() {
      this.$router.push("/about-us");
    },
    goContactUs() {
      this.$router.push("/contact-us");
    },
    goLogin() {
      this.$router.push("/login");
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    goRegister() {
      this.$router.push("/register");
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    loginAndRegisterHandler() {
      this.login_and_register_modal = !this.login_and_register_modal;
    }
  }
};
</script>
<style scoped>
#footer {
  margin-top: 59px;
  width: 100%;
  background-color: #070d59;
  display: flex;
  flex-direction: column;
}

.footer-container {
  height: 335px;
  padding: 0 70px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  background-image: url("../assets/images/right_illutration.png");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  display: flex;
  flex-direction: column;
  height: auto;
}

.column {
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.header {
  align-self: center;
  margin-bottom: 5px;
}

.liner {
  margin-top: 5px;
  margin-bottom: 15px;
}

.body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: auto;
}

.body div {
  margin-bottom: 12px;
}

.contact-us {
  width: 100%;
  height: 235px;
  display: flex;
  flex-direction: column;
}

.address {
  display: flex;
  flex-direction: column;
}

.address div {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
}

.news {
  max-width: 372px;
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  display: flex;
  align-self: center;
  min-height: 30px;
}

.news input {
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  padding-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #121212;
}

.btn {
  width: 103px;
  background: rgba(255, 255, 255);
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  color: #070d59;
}

.liner {
  width: 100%;
}

.liner-bottom {
  width: 100%;
  padding-top: 18px;
  margin-bottom: 35px;
  display: none;
}

.copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
}

.scoal {
  font-size: 0;
}

img {
  width: 30px;
}

.links {
  display: flex;
  justify-content: center;
}

.column div {
  cursor: pointer;
}

/* @media (min-width: 570px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
  }
} */

@media (max-width: 320px) {
  .liner-bottom {
    display: unset;
    margin-bottom: 15px;
  }
}

@media (max-width: 280px) {
  .liner-bottom {
    display: block;
  }
}
@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
  }

  .news {
    width: 100%;
  }

  img {
    width: auto;
  }

  .copyright {
    text-align: right;
  }

  .liner-bottom {
    display: block;
    margin-bottom: unset;
  }
}

@media (min-width: 1200px) {
  .footer-container {
  }

  .contact-us {
    width: auto;
  }

  #footer {
    padding: 0 70px;
  }
}
</style>
